var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"food-list"},[_c('div',{staticClass:"food-list__heading"},[_c('h3',{staticClass:"food-list__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.editable)?_c('van-button',{staticClass:"food-list__add",attrs:{"icon":"plus","round":"","type":"primary","to":{
        name: 'FoodList',
        params: {
          mealType: _vm.mealType,
        }
      }}}):_vm._e()],1),_c('van-cell-group',{staticClass:"food-list__items"},[(_vm.hasItems)?_vm._l((_vm.items),function(item){return _c('van-swipe-cell',{key:item.id,attrs:{"before-close":_vm.beforeClose},scopedSlots:_vm._u([(_vm.editable)?{key:"right",fn:function(){return [_c('van-button',{attrs:{"square":"","type":"danger","text":_vm.$t('clear')},on:{"click":function($event){return _vm.removeFood(item)}}})]},proxy:true}:null],null,true)},[_c('food-item',_vm._b({attrs:{"nutritions":item.nutritionsCalculated || item.nutritions,"diagnosis":_vm.diagnosis,"food-type":item.foodType,"food-icon":"meal"},nativeOn:{"click":function($event){return _vm.toMealDetails(item)}}},'food-item',item,false),[_c('van-icon',{attrs:{"name":_vm.$icon('arrow-list'),"size":"10"}})],1)],1)}):(_vm.$slots.default)?_vm._t("default"):_c('p',{staticClass:"food-list__empty"},[_vm._v(" "+_vm._s(_vm.$t('foodList.empty'))+" ")]),(!_vm.containsMeal)?_c('div',{staticClass:"food-list__save-meal"},[(_vm.editable)?_c('van-button',{attrs:{"text":_vm.$t('foodList.save'),"block":"","type":"plain","to":{
          name: 'MealDetails',
          params: {
            mealType: _vm.mealType,
          }
        }},on:{"click":_vm.prepareFoodItems}}):_vm._e()],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }