<template>
  <div class="template-details">
    <van-nav-bar
      :title="this.$route.params.title || $t(`food.mealTypes.template`)"
      :right-text="$t('close')"
      fixed
      placeholder
      @click-right="goBack()"
    />

    <div class="meal-summary">
      <template v-for="(items, mealType) in lists">
        <food-list
          :key="mealType"
          :title="$t(`food.mealTypes.${mealType}`)"
          :meal-type="mealType"
          :items="items"
          :diagnosis="diagnosis"
          :editable="false"
        />
      </template>
    </div>
    <van-button
      class="clone-template"
      type="primary"
      block
      round
      @click="copyTemplateToDate"
    >
      {{ $t('template.cloneTemplate') + ` ${date}` }}
    </van-button>
  </div>
</template>

<script>
import { format } from 'date-fns';

import Dayplan from '@/models/dayplan';
import Diagnosis from '@/models/diagnosis';
import FoodList from '@/components/FoodList';

import { analytics } from '@/firebase';

export default {
  components: {
    FoodList,
  },
  data() {
    return {
      date: null,
      diagnosis: null,
      foods: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
      meals: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
      mealItems: {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: [],
      },
    };
  },
  computed: {
    lists() {
      const lists = {};
      Object.keys(this.foods).forEach((key) => {
        const items = this.foods[key].concat(this.mealItems[key]);
        items.sort((a, b) => a.added?.seconds - b.added?.seconds);
        lists[key] = items;
      });
      return lists;
    },
  },
  watch: {
    meals: {
      deep: true,
      handler(mealTypes) {
        Object.entries(mealTypes).forEach((entry) => {
          const [mealType, docs] = entry;
          const items = [];
          docs.forEach((documentData) => {
            const item = {
              id: documentData.id,
              ...documentData.ref,
              ...documentData,
            };
            items.push(item);
          });
          this.mealItems[mealType] = items;
        });
      },
    },
    '$store.state.dayplan.selectedDate': {
      immediate: true,
      handler() {
        Dayplan.updateNutritionSums();
        Object.keys(this.lists).forEach((mealType) => {
          this.$bind(`foods.${mealType}`, Dayplan.getFoods(mealType));
          this.$bind(`meals.${mealType}`, Dayplan.getMeals(mealType), { wait: true });
        });
      },
    },
  },
  created() {
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.date = format(this.$store.getters['template/selectedDate'], 'dd.MM.yyyy');
    this.$store.commit('meal/reset');
  },
  methods: {
    goBack() {
      this.setSelectedDate();
      this.$router.back();
    },
    setSelectedDate() {
      const selectedDate = format(this.$store.getters['template/selectedDate'], 'yyyy-MM-dd');
      this.$store.commit('dayplan/selectDate', new Date(selectedDate));
    },
    // Pass imported function into template.
    copyTemplateToDate() {
      const data = this.$store.getters['template/templateNutritions'];
      Dayplan.templateRef().set({ nutritionSums: data });
      this.$store.commit('dayplan/setNutritionSums', data);
      const mealCollection = Dayplan.reference().collection('meals');
      const foodCollection = Dayplan.reference().collection('foods');
      mealCollection.get().then((mealRef) => {
        if (mealRef.docs) {
          mealRef.docs.forEach((meal) => {
            Dayplan.templateRef().collection('meals').add(meal.data());
          });
        }
      });
      foodCollection.get().then((foodref) => {
        if (foodref.docs) {
          foodref.docs.forEach((food) => {
            Dayplan.templateRef().collection('foods').add(food.data());
          });
        }
      });
      this.setSelectedDate();

      analytics.logEvent('day_add_template', {
        template_name: this.$route.params.title,
      });

      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.clone-template {
  width: 90%;
  margin: 16px auto;
}
</style>
