<template>
  <div class="food-list">
    <div class="food-list__heading">
      <h3 class="food-list__title">
        {{ title }}
      </h3>
      <van-button
        v-if="editable"
        icon="plus"
        round
        type="primary"
        class="food-list__add"
        :to="{
          name: 'FoodList',
          params: {
            mealType,
          }
        }"
      />
    </div>
    <van-cell-group class="food-list__items">
      <template v-if="hasItems">
        <van-swipe-cell
          v-for="item in items"
          :key="item.id"
          :before-close="beforeClose"
        >
          <food-item
            v-bind="item"
            :nutritions="item.nutritionsCalculated || item.nutritions"
            :diagnosis="diagnosis"
            :food-type="item.foodType"
            food-icon="meal"
            @click.native="toMealDetails(item)"
          >
            <van-icon
              :name="$icon('arrow-list')"
              size="10"
            />
          </food-item>
          <template
            v-if="editable"
            #right
          >
            <van-button
              square
              type="danger"
              :text="$t('clear')"
              @click="removeFood(item)"
            />
          </template>
        </van-swipe-cell>
      </template>
      <slot v-else-if="$slots.default" />
      <p
        v-else
        class="food-list__empty"
      >
        {{ $t('foodList.empty') }}
      </p>
      <div
        v-if="!containsMeal"
        class="food-list__save-meal"
      >
        <van-button
          v-if="editable"
          :text="$t('foodList.save')"
          block
          type="plain"
          :to="{
            name: 'MealDetails',
            params: {
              mealType,
            }
          }"
          @click="prepareFoodItems"
        />
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import { analytics, FieldValue } from '@/firebase';
import { Dialog } from 'vant';

import Dayplan from '@/models/dayplan';
import Meal from '@/models/meal';
import FoodItem from '@/components/FoodItem';

export default {
  components: {
    FoodItem,
  },
  props: {
    diagnosis: { type: Object, default: null, required: true },
    title: { type: String, required: true },
    mealType: { type: String, required: true },
    items: { type: Array, required: true },
    editable: { type: Boolean, default: true },
  },
  computed: {
    itemIds() {
      return this.items.map((item) => item.id);
    },
    // Returns false if the list of items contains a `meal` food type.
    containsMeal() {
      return !this.hasItems || this.items.some((food) => food.foodType === 'meal');
    },
    hasItems() {
      return Object.keys(this.items).length > 0;
    },
  },
  methods: {
    prepareFoodItems() {
      this.$store.commit('meal/setFoodItems', this.items);
    },
    removeFood(item) {
      Dialog.confirm({
        message: this.$i18n.t('dialog.delete'),
      }).then(() => {
        if (item.mealTypes.length) {
          this.$delete(item.mealTypes, item.mealTypes.indexOf(this.mealType));
        }
        let data = {};
        const foodType = item.foodType || null;
        if (foodType === 'meal') {
          data = {
            id: item.id,
            foodType: 'meal',
          };
        } else {
          data = {
            id: item.id,
            ...item,
          };
        }
        if (item.mealTypes.length) {
          Dayplan.updateFood({
            ...data,
            mealTypes: FieldValue.arrayRemove(this.mealType),
          }, foodType);
        } else {
          Dayplan.removeFood(item.id, foodType);
        }
        analytics.logEvent(foodType === 'meal' ? 'day_remove_meal' : 'day_remove_food', {
          item_id: item.id,
          item_name: item.title,
          item_category: foodType === 'meal' ? foodType : item.category,
          item_list_id: this.mealType,
        });
      });
    },
    beforeClose({ position, instance }) {
      if (position === 'outside') {
        instance.close();
      }
    },
    toMealDetails(item) {
      this.$store.commit('meal/setMealTitle', item.title);
      const foodItems = [];
      if (item.foodType === 'meal') {
        const mealsMeta = Meal.getMealFoods(item.id);
        this.$store.commit('meal/setMealId', item.id);
        mealsMeta.then((mealFoods) => {
          mealFoods.docs.forEach((mealFood) => {
            foodItems.push(mealFood.data());
            this.$store.commit('meal/setFoodItems', foodItems);
          });
        });
        this.$router.push({
          name: 'MealDetails',
          params: {
            mealType: this.mealType,
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: 'FoodDetailsView',
          params: {
            mealType: this.mealType,
            id: item.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.food-list {
  min-height: 10rem;
  margin-bottom: config.$spacing;
  background-color: config.$color-light;
}

.food-list__heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem 0 0.8125rem;
  border-bottom: 1px solid #dedede;

  .meal-summary & {
    margin: 0 #{config.$spacing};
  }
}

.food-list__title {
  margin: 0;
  font-size: config.$font-size-md;
  font-weight: bold;
  @media(min-width: 1024px) {
    font-size: 22px;
  }
}

.food-list__add {
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.125rem;

  .van-button__icon {
    line-height: 1;
    font-size: 1rem;
    color: #fff;
  }
}

.food-list__empty {
  padding-right: #{config.$spacing};
  padding-left: #{config.$spacing};
}

.food-list__items {
  background-color: transparent;
}

.food-list__save-meal {
  padding: 0.625rem 0 0;

  .van-button {
    border: none;
    background: transparent;
    font-size: config.$font-size-sm;
    font-weight: 600;
    line-height: 1.71;
    @media(min-width: 1024px) {
      font-size: config.$font-size;
    }
  }
}
</style>
